import Request from "../Request";

const FFSOLAR_SERVER_URL = window.runtimeConfig?.FFSOLAR_SERVER_URL;

const airteam3DViewer = {
  getModelPreview(modelId) {
    return Request.get(
      `/project/airteam-viewer/api/v1/viewer-model-preview/${modelId}/`
    );
  },
  getFfSolarObjectsByProjectId(projectId) {
    return Request.get(
      `${FFSOLAR_SERVER_URL}/ffsolarobject?projectId=${projectId}`
    );
  },
  createObject(data) {
    return Request.postWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject`,
      data
    );
  },
  updateObject(data) {
    return Request.putWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject`,
      data
    );
  },
  deleteObject(id) {
    return Request.deleteWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/${id}`
    );
  },
  deleteBulkPanelObjects(data) {
    return Request.deleteWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/bulk`,
      data
    );
  },
  getCustomPanels(projectId) {
    let url = `${FFSOLAR_SERVER_URL}/custompaneltype`;
    if (projectId) {
      url += `?projectId=${projectId}`;
    }
    return Request.getWithAccessToken(url);
  },
  createCustomPanel(data) {
    return Request.postWithAccessToken(
      `${FFSOLAR_SERVER_URL}/custompaneltype`,
      data
    );
  },
  updateCustomPanel(data) {
    return Request.patchWithAccessToken(
      `${FFSOLAR_SERVER_URL}/custompaneltype`,
      data
    );
  },
  deleteCustomPanel(data) {
    return Request.deleteWithAccessToken(
      `${FFSOLAR_SERVER_URL}/custompaneltype`,
      data
    );
  },
  getDefaultPanelTypes() {
    return Request.get(`${FFSOLAR_SERVER_URL}/paneltype`);
  },
  createPanelObject(data) {
    return this.createObject({ type: "PANEL", ...data });
  },
  createBulkPanelObjects(array) {
    return Request.postWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/bulk`,
      array
    );
  },
  createAreaObject(data) {
    return this.createObject({ type: "AREA", ...data });
  },
  createMeasurementObject(data) {
    return this.createObject({ type: "MEASUREMENT", ...data });
  },
  createMeasurementAreaObject(data) {
    return this.createObject({ type: "AREA_MEASUREMENT", ...data });
  },
  updateAreaObject(data) {
    return this.updateObject({ type: "AREA", ...data });
  },
  updateMeasurementObject(data) {
    return this.updateObject({ type: "MEASUREMENT", ...data });
  },
  updateMeasurementAreaObject(data) {
    return this.updateObject({ type: "AREA_MEASUREMENT", ...data });
  },
  updatePanelObject(data) {
    return this.updateObject({ type: "PANEL", ...data });
  },
  updateBulkPanelObject(array) {
    return Request.putWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/bulk`,
      array
    );
  },
};

export default airteam3DViewer;
